import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const columns = [
    {
        Header: 'SERVICE NAME',
        accessor: 'serviceName',
    },
    {
        Header: 'URL',
        accessor: 'url',
    },
    {
        Header: 'STATUS',
        accessor: 'availability',
        Cell: ({ cell: { value } }) => {
            if(value == "100"){
                return <CheckCircleIcon sx={{color: "#04cf3d"}} />
            } else if(value < "100" && value > "50"){
                return <ErrorIcon sx={{color: "#dea704"}} />
            } else if(value < "50"){
                return <ErrorIcon sx={{color: "red"}} />
            }
        }
    },
    {
        Header: 'TIMESTAMP',
        accessor: 'timestamp',
        Cell: ({ cell: { value } }) => new Date(value).toISOString()
    }
]