import React, { useState, useCallback } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTable, useSortBy } from "react-table";
import SortIcon from '@mui/icons-material/Sort';

const { columns } = require("../../../constants/columns");

function ResultTable({ data }) {
    const [sortBy, setSortBy] = useState("serviceName");

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                autoResetSortBy: false,
                initialState: {
                    sortBy: [
                        {
                            id: sortBy,
                            desc: true
                        }
                    ],
                }
            },
            useSortBy
        );
    return (
        <Paper sx={{ width: "100%", overflow: "auto" }}>
            <Table stickyHeader {...getTableProps()}>
                <TableHead sx={{ backgroundColor: "orange" }}>
                    {headerGroups.map((headerGroup) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <TableCell
                                    sx={{ backgroundColor: "#134b5f", color: "white" }}
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                >
                                    {column.render("Header")}
                                    <span style={{
                                        marginLeft: "10px",
                                        position: "absolute"
                                    }}>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <SortIcon />
                                                : <SortIcon sx={{
                                                    transform:
                                                        "rotate(180deg) scaleX(-1)",
                                                }} />
                                            : ''}
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <TableRow sx={{ textAlign: "center" }} {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <TableCell
                                            {...cell.getCellProps()}
                                            sx={{ paddingTop: "20px" }}
                                        >
                                            {cell.render("Cell")}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default ResultTable;
