import React, { useState, useCallback } from "react";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import ResultTable from "../../organisms/result_table";
import { initialDataLoad } from "../../../api/initial_data_load";

const { REACT_APP_SOCKET_URL } = process.env

function MainPage() {
    const [data, setData] = useState([]);

    const loadInitialData = async () => {
        const resp = await initialDataLoad();
        resp.forEach(testResult => {
            setData((old) => [
                ...old.filter((oldResult) => oldResult.serviceName !== testResult.serviceName),
                testResult,
            ]);    
        });
    }

    const onSocketMessage = useCallback((event) => {
        const { data } = event;
        const testResult = JSON.parse(data);
        setData((old) => [
            ...old.filter((oldResult) => oldResult.serviceName !== testResult.serviceName),
            testResult,
        ]);
    });

    const handleConnect = async () => {
        const socket = new WebSocket(
            REACT_APP_SOCKET_URL
        );
        socket.addEventListener("message", onSocketMessage);
    };

    React.useEffect(() => {
        loadInitialData();
        handleConnect();
    }, []);

    return (
        <Box
            sx={{
                marginTop: "50px",
                color: "rgba(0, 0, 0, 0.6)",
                borderRadius: "15px",
                backgroundColor: "rgb(255, 255, 255)",
                width: "80%",
                height: "90vh",
                padding: ["30px", "30px"],
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    fontSize: "1.2rem",
                    fontWeight: 350,
                    letterSpacing: "0.02em",
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "35px"
                }}
            >
                <Box>
                    <img
                        className="logo"
                        src="/ncelogo.png"
                        width={100}
                        height={100}
                    ></img>
                </Box>
                <Box
                    sx={{
                        flex: "100%",
                        textAlign: "center",
                        marginRight: "50px",
                        fontSize: "20px",
                    }}
                >
                    <b>INTERNAL TOOL STATUS</b>
                </Box>
            </Box>

            <ResultTable data={data} />

        </Box>
    );
}

export default MainPage;
