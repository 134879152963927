import Routing from './routes'
import { BrowserRouter as Router } from "react-router-dom";
import { Box } from '@mui/system';

function App() {
  return (
      <Box 
        sx={{
          backgroundImage: "linear-gradient(to right, rgba(90,166,66,255), rgba(38,140,182,255))",
          minHeight:"100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          fontFamily: ["Barlow", "sans-serif"],
        }}
      >
          <Router>
            <Routing/>
          </Router>
      </Box>
  );
}

export default App;
