import { handleFetch } from "./index";

const { REACT_APP_GATEWAY_URL } = process.env;

export const initialDataLoad = () => {
    return handleFetch(
        `${REACT_APP_GATEWAY_URL}/availability/results`,
        {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        }
    )
}