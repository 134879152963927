import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import MainPage from "./components/pages/main";

function Routing () {
    return (
        <Routes>
            <Route path="/" element={<MainPage/>} />
            <Route path="*" element={<Navigate to="/"/>} />
        </Routes>   
    )
} 

export default Routing;